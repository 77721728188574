import { configureStore } from "@reduxjs/toolkit";
import SessionSlice from "./Reducers/SessionSlice";
import { useCookies } from "react-cookie";

const LFEStore = configureStore({
    reducer: {
      session: SessionSlice
    }
})

export default LFEStore;

