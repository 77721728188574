import React, { Component, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Index } from './Index';
import { useCookies } from 'react-cookie';
import LysepAPI from '../Contexts/ApiContext';
import { InitializeSession } from '../Contexts/Reducers/SessionSlice';
import PrivateRoute from './PrivateRoute';
import Dashboard from './PanelRoutes/Dashboard';
import LysepThemeProvider from '../Contexts/ThemeProvider';
import { Backdrop, CircularProgress } from '@mui/material';


function MainLoading() {
    return (<Backdrop
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      >
        <CircularProgress color="inherit" />
      </Backdrop>)
}

export default function Router() {
    
    const session = useSelector(state=>state.session)
    const [cookies,setCookie] = useCookies(["__lfe_session"]);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!session.IsInitialized){
            if(cookies["__lfe_state"]){
                LysepAPI.post("/api/auth/user").then(resp=>{
                  if(resp.data.state){
                    dispatch(InitializeSession({
                        Authenticated:true,
                        User:resp.data.user
                    }))
                    setCookie("__lfe_state",true)
                  } else{
                    dispatch(InitializeSession({
                        Authenticated:false,
                        User:null
                    }))
                    setCookie("__lfe_state",false)
                  } 
                })
            }else{
                setCookie("__lfe_state",false)
                dispatch(InitializeSession({
                    Authenticated:false,
                    User:null
                }))
            }
        }
    },[])

    return (
        <LysepThemeProvider>
            {
             session.IsInitialized?( <BrowserRouter>
                <Routes>
                    <Route path="/" Component={Index}>
                    </Route>
                    <Route path='/login' Component={Login}></Route>
                    <Route path='/panel/' Component={PrivateRoute}>
                        <Route path='/panel/dashboard/' Component={Dashboard}/>
                        <Route path='/panel/dashboard/:page' Component={Dashboard}/>
                    
                    </Route>
                </Routes>
            </BrowserRouter>) : MainLoading()
            }
           
        </LysepThemeProvider>
    )
}
