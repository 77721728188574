import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { DashboardConsumer } from '../PanelRoutes/Dashboard';
import './Navbar.scss';

import { AppBar, Button, IconButton, InputBase, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { AccountCircleOutlined, MenuBookOutlined, MenuRounded, SearchOutlined } from '@mui/icons-material';
import Logo from './LogoComponent';

// import './../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
export default class Navbar extends Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
    }
    constructor(props){
        super(props)
        this.Logout = this.Logout.bind(this);
    }
    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        // this.handleMobileMenuClose();
      };
    componentDidMount(){
        // this.dropdownHandle()
    }
    dropdownHandle(){
        // var ddowns =  document.querySelectorAll(".dropdown-item");
        // ddowns.forEach(element => {
        //     var trigger = element
        //     var triggerpe = trigger.getAttribute("onprocessend")
            
        //     trigger.onclick=()=>{
        //         var ddown = element.querySelector(".dropdown-menu")
        //         var classes = ddown.className.split(" ")

        //         if(classes.includes("active")){
        //             classes = classes.filter(x => x!="active")
        //             /*Closing*/
        //             setTimeout(() => {
        //                 // ddown.style.display = "none"
        //             }, 300);
        //         }else{
        //             // ddown.style.display = "block"
        //             /*Opening*/
        //             ddowns.forEach((element)=>{
        //                 var target = element.querySelector(".dropdown-menu");
        //                 var target_classes = target.className.split(" ")
        //                 if(target_classes.includes("active")){
        //                     target_classes = target_classes.filter(x => x!="active")
        //                 }
        //                 target.className = target_classes.join(" ")
        //             })
        //             classes.push("active")

        //         }
        //         ddown.className=classes.join(" ");
        //     }
        // });   
    }
    Logout(){
        
    }
    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);


        let handleSidebar = (event,Dashboard)=>{
            Dashboard.dispatch(Dashboard,{
                name:"SET_SIDEBAR",
                data:!Dashboard.sidebarState
            })
            }
        return (
            <DashboardConsumer>
                    {Dashboard=>(
                            <AppBar position="static" className='navbar'>
                                <Toolbar>
                                <IconButton onClick={(e)=>handleSidebar(e,Dashboard)} color="inherit" aria-label="Menu">
                                    <MenuRounded />
                                </IconButton>
                                <Typography style={{flexGrow:1,marginLeft:10}} variant="h6" color="inherit" noWrap>
                                    LYSEP
                                </Typography>
                                <IconButton
                                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircleOutlined />
                                </IconButton>
                                </Toolbar>
                                {/* <div className="navbar-container">
                                <div className="start">
                                    <DashboardConsumer>
                                    {Dashboard=>(
                                        <>
                                        <a className="burgermenu" onClick={(event)=>{
                                        Dashboard.dispatch(Dashboard,{
                                            name:"SET_SIDEBAR",
                                            data:!Dashboard.sidebarState
                                        })
                                        }}><Menu></Menu></a>
                                        <a className="brand">{Dashboard.Title}</a>
                                        </>
                                    )}
                                    </DashboardConsumer>
                                </div>
                                
                                    <div className="end">
                                        <ul className="controls">
                                            <li className="dropdown-item nav-item" >
                                                <i className="fal fa-user"></i>
                                                <div className="dropdown-menu">
                                                    <ul className="dropdown-list">
                                                        <li>Profile</li>
                                                        <li className="hr">
                                                        </li>
                                                        <li onClick={this.Logout}>Exit</li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className="dropdown-item nav-item">
                                                <i className="fal fa-globe"></i>
                                                <div className="dropdown-menu">
                                                    <ul className="dropdown-list">
                                                        <li>Website</li>
                                                        <li>Discord</li>
                                                        
                                                        {/* {session.Information.permission&(session.Information.permission.ROOT_PERMISSION || session.Information.permission.MODERATOR || session.Information.permission.ADMINISTRATOR)
                                                        ? (<div><li className="hr"/><li>Services</li></div>) : ""
                                                        } 
                                                        
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                            
                            </div> */}
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={isMenuOpen}
                                onClose={this.handleMenuClose}
                                >
                                <MenuItem onClick={this.handleMenuClose}>Çıkış Yap</MenuItem>
                                </Menu>
                            </AppBar>
                            )}
                            
                    </DashboardConsumer>
                
        )
    }
}
