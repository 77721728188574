import { Close, Edit, Save } from '@mui/icons-material';
import { AppBar, Button, CircularProgress, Dialog, Divider, FormControl, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Paper, TextField, Toolbar, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';
import React, { Component, useState } from 'react'
import { Slide } from 'react-toastify';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
// function permissionChangeEventHandle(event,id,stateCallback){
//     let permString = {}
//     var nn = event.target.name;
//     var ttc = event.target.checked;
//     permString[ String(Math.log2(UserPermissions[nn]))] = ttc ? 1 : 0
//     let mfdata = new FormData();
//     mfdata.append("map",JSON.stringify(permString))
//     awxc({
//         method:"POST",
//         url:getApiHost(`manage/users/${id}/permission`),
//         data:mfdata,
//         headers:{"Content-Type":"multipart/form-data"}
//     }).then(resp=>{
//         if(resp.data.state){
//             toast.success("Successfully changed permission.")
//             stateCallback(resp,nn,ttc)
//         }else{
//             toast.success("WHO ARE U?")
//         }
//     }).catch(resp=>{
//         toast.error("Connection error")
//     })
// }
function EditUserDialog(props){
    const [state, setState] = useState({
        data:props.selectedData ? props.selectedData : {
            "id": "0",
            "username": "undefined",
            "email": "undefined",
            "token": null,
            "token_expiration": 0,
            "permission": {
                "ROOT_PERMISSION": false,
                "ADMINISTRATOR": false,
                "MODERATOR": false,
                "USER": true
            },
            "permission_raw": "00010000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
            "state": 255
        },
    })
    if(!state.data| (state.data.id!=props.selectedData.id)){
        setState({...state,
        data:props.selectedData
        })
    }

    return (<Dialog className="UsersEditDialog" fullScreen open={props.isOpened} onClose={props.closeHandle} TransitionComponent={Transition}>
                    <AppBar style={{position:"relative"}}>
                        <Toolbar style={{color:"white"}}>
                            {/* <IconButton edge="start" color="inherit" onClick={props.closeHandle} aria-label="close"> */}
                                <Edit />
                            {/* </IconButton> */}
                            <Typography variant="h6" style={{flex: 1,marginLeft:"5px"}}>Edit user - {state.data.id}</Typography>
                            <Button autoFocus color="inherit" onClick={props.closeHandle}><Close/></Button>
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Main Informations" secondary={
                                        <Grid container spacing={2}>
                                            {/* <Grid item xs={12} style={{marginTop:"5px"}}>
                                                <ListItemText primary="ID" secondary={state.data.id}/>
                                            </Grid> */}
                                            <Grid item xs={12} style={{marginTop:"5px"}}>
                                                <TextField fullWidth id="UsernameTF" value={state.data.username} onChange={(event)=>{
                                                    setState({...state,
                                                        data:{...state.data,username:event.target.value}
                                                    })
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                                        <IconButton
                                                                        onClick={(event)=>{
                                                                            var bodyFormData = new FormData();
                                                                            bodyFormData.append("username",state.data.username)
                                                                            // awxc({
                                                                            //     method:"post",
                                                                            //     url:getApiHost(`manage/users/${state.data.id}/username`),
                                                                            //     data:bodyFormData,
                                                                            //     headers:{ "Content-Type": "multipart/form-data" }
                                                                            // }).then((resp)=>{
                                                                            //     if(resp.data.state){
                                                                            //         toast.success(`Successfully changed username to ${state.data.username}`)
                                                                            //     }else{
                                                                            //         toast.warn("Change error?")
                                                                            //     }
                                                                            // }).catch((resp)=>{
                                                                            //     toast.error("Connection error.")
                                                                            // })
                                                                        }}
                                                                        edge="end"
                                                                        >
                                                                        <Save></Save>
                                                                        </IconButton>
                                                                    </InputAdornment>,
                                                  }} label="Username" variant="outlined" />
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <TextField fullWidth id="PasswordTF" label="Password" variant="outlined" />
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <TextField fullWidth id="EmailTF" value={state.data.email}
                                                
                                                onChange={(event)=>{
                                                    setState({...state,
                                                        data:{...state.data,email:event.target.value}
                                                    })
                                                }} InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                                        <IconButton
                                                                        onClick={(event)=>{
                                                                                var bodyFormData = new FormData();
                                                                                bodyFormData.append("email",state.data.email)
                                                                                
                                                                        }}
                                                                        edge="end"
                                                                        >
                                                                        <Save></Save>
                                                                        </IconButton>
                                                                    </InputAdornment>,
                                                  }} label="Email" variant="outlined" />
                                            </Grid>
                                        </Grid>
                                    } />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText primary="Account Controls" secondary={
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{marginTop:"5px"}}>
                                                <FormControl component="fieldset" style={{marginLeft:"5px"}}>
                                                    <FormLabel component="legend">User State</FormLabel>
                                                    
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    } />
                                </ListItem>
                            </List>
                        </Grid>
                        {state.data.permission ? (<Grid item xs={12} sm={6} md={6}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Configuration" secondary={
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{marginTop:"5px"}}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Roles</FormLabel>
                                                <FormGroup style={{marginTop:"10px"}}>
                                                    {/* <FormControlLabel
                                                        control={<Checkbox checked={state.data.permission.ROOT_PERMISSION} onChange={(event)=>{permissionChangeEventHandle(
                                                            event,state.data.id,(resp,PName,Statement)=>{let newState = {...state,data:{...state.data,permission_raw:resp.data.permission_raw}};newState.data.permission[PName]=Statement;setState(newState)})}} name={UserPermissions.Strings[UserPermissions.ROOT_PERMISSION]} />}
                                                        label={UserPermissions.Strings[UserPermissions.ROOT_PERMISSION]}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.data.permission.ADMINISTRATOR | state.data.permission.ROOT_PERMISSION} onChange={(event)=>{permissionChangeEventHandle(
                                                            event,state.data.id,(resp,PName,Statement)=>{let newState = {...state,data:{...state.data,permission_raw:resp.data.permission_raw}};newState.data.permission[PName]=Statement;setState(newState)})}} name={UserPermissions.Strings[UserPermissions.ADMINISTRATOR]} />}
                                                        label={UserPermissions.Strings[UserPermissions.ADMINISTRATOR]}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.data.permission.MODERATOR | state.data.permission.ROOT_PERMISSION | state.data.permission.ADMINISTRATOR} onChange={(event)=>{permissionChangeEventHandle(
                                                            event,state.data.id,(resp,PName,Statement)=>{let newState = {...state,data:{...state.data,permission_raw:resp.data.permission_raw}};newState.data.permission[PName]=Statement;setState(newState)})}} name={UserPermissions.Strings[UserPermissions.MODERATOR]} />}
                                                        label={UserPermissions.Strings[UserPermissions.MODERATOR ]}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.data.permission.USER} onChange={(event)=>{permissionChangeEventHandle(event,state.data.id,(resp,PName,Statement)=>{let newState = {...state,data:{...state.data,permission_raw:resp.permission_raw}};newState.data.permission[PName]=Statement;setState(newState)})}} name={UserPermissions.Strings[UserPermissions.USER]} />}
                                                        label={UserPermissions.Strings[UserPermissions.USER]}
                                                    /> */}
                                                    
                                                </FormGroup>
                                                <FormHelperText>Be careful</FormHelperText>
                                            </FormControl>
                                               
                                            </Grid>
                                        </Grid>
                                    } />
                                </ListItem>
                                <Divider />
                            </List>
                        </Grid>) : <></>}
                    </Grid>
                    {/* <List>
                        <ListItem button>
                            <ListItemText primary="Phone ringtone" secondary="Titania" />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                        </ListItem>
                    </List> */}
                </Dialog>)
}


export default class UsersTable extends Component {
    state = {
        isInitialized:false,
        columns:["ID","Username","Email","State","Token","Actions"],
        data:[],
        selectedData:{},
        rawData:[],
        query:"",
        rowsPerPage:10,
        rowCount:0,
        start:0,
        failed:false,
        needUpdate:false,
        isLoading:false,
        isEditOpened:false
    }
    componentDidMount(){
        // var bodyFormData = new FormData();
        // bodyFormData.append("query",this.state.query)
        // bodyFormData.append("limit",this.state.rowsPerPage)
        // bodyFormData.append("start",this.state.start)
        // bodyFormData.append("oba",0)
        // awxc({
        //     method:"post",
        //     url:getApiHost("view/users"),
        //     data:bodyFormData,
        //     headers:{ "Content-Type": "multipart/form-data" }
        // }).then(rp=>{
        //     let data = rp.data
        //     if(data.state){
        //         this.setState({
        //             ...this.state,
        //             rawData:data.users,
        //             rowCount:data.totalRows,
        //             data:data.users.map(userRow=>{
        //                 return [userRow.id,userRow.username,userRow.email,userRow.state == 255 ? <VerifiedUser/> : <WarningRounded/>,userRow.token ? `${userRow.token.substr(0,5)}...` : `Not signed in`]
        //             }),
        //             isInitialized:true,
        //             needUpdate:false
        //         })
        //     }else{
        //         this.setState({
        //             ...this.state,
        //             failed:true,
        //             rowCount:0,
        //             data:[],
        //             isInitialized:true,
        //             needUpdate:false
        //         })
        //     }
            
        // }).catch(rp=>{
        //     this.setState({
        //         ...this.state,
        //         failed:true,
        //         rowCount:0,
        //         data:[],
        //         isInitialized:true,
        //         needUpdate:false
        //     })
        // })
        this.makeRequest(this.state.start/this.state.rowsPerPage,this.state.rowsPerPage,this.state.query)

    }
    makeMultipleDeleteRequest(list){
        let deleteArray =  {"list":list.map(el=>{
            return el[0]
        })}
        var bodyFormData = new FormData();
        bodyFormData.append("list",JSON.stringify(deleteArray))
        
    }
    makeRequest(pageid,rwp,query){
        var bodyFormData = new FormData();
        bodyFormData.append("query",query ? query : "")
        bodyFormData.append("limit",rwp)
        bodyFormData.append("start",pageid*rwp)
        bodyFormData.append("oba",0)
        
    }
    render() {
        
        return (
        <Paper className="mainMuiTable" elevation="2">
                <EditUserDialog isOpened={this.state.isEditOpened} closeHandle={(event)=>{
                    this.setState({...this.state,isEditOpened:!this.state})
                }}
                selectedData={this.state.selectedData}
                /> 
                <MUIDataTable
                    title={
                        <Typography variant="h6">
                            LYSEP Users
                            {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                        </Typography>
                    }
                    columns={
                        this.state.columns
                    }
                    data={
                        this.state.data
                    }
                    options={{
                        count:this.state.rowCount,
                        serverSide: true,
                        filter: false,
                        filterType: 'dropdown',
                        responsive: 'vertical',
                        rowsPerPageOptions: [5,10,20,30,40,50],
                        onRowsDelete:(action,a,b)=>{
                          var listA = [];
                          action.data.forEach(el => {
                            let id = this.state.data[el.dataIndex];
                            listA.push(id)
                          });
                          this.makeMultipleDeleteRequest(listA)
                          return false
                        },
                        onTableChange: (action, tableState) => {
                            switch (action) {
                              case 'changePage':
                                this.makeRequest(tableState.page,tableState.rowsPerPage,tableState.searchText);
                                break;
                              case 'sort':
                                this.makeRequest(tableState.page,tableState.rowsPerPage,tableState.searchText);
                                break;
                              case "search":
                                this.makeRequest(tableState.page,tableState.rowsPerPage,tableState.searchText);
                                  break;
                              case "changeRowsPerPage":
                                this.makeRequest(tableState.page,tableState.rowsPerPage,tableState.searchText);
                                break;
                              default:
                                console.log('action not handled.');
                            }
                          }
                    }}
                >
                </MUIDataTable>
            </Paper>
        )
    }
}
