import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    IsInitialized:false,
    Authenticated: false,
    User:null
  },
  reducers: {
    InitializeSession:(state,action)=>{
      console.log("Initializing session.",action.payload)
      state.IsInitialized = true
      state.Authenticated = action.payload.Authenticated
      state.User = action.payload.User
    },
    Login: (state,action) => {
      state.Authenticated = true
      state.User = action.payload
    },
    Logout:(state)=>{
        state.Authenticated = false
        state.User = null
    }
  }
})

// Action creators are generated for each case reducer function
export const { Login, Logout,InitializeSession } = sessionSlice.actions

export default sessionSlice.reducer