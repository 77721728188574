import React, { Component } from 'react'
import './WrapperPanel.scss'
export default class WrapperPanel extends Component {
    render() {
        return (
            <div className="wrapper-panel">
                {this.props.children}
            </div>
        )
    }
}
