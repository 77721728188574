import axios from "axios";
import LFEStore from "./SessionProvider"
import { Logout } from "./Reducers/SessionSlice";
axios.defaults.withCredentials = true

let baseURL = 'http://localhost:35151/';
if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  baseURL = 'https://api.lysep.com/'
} 

const LysepAPI = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    withCredentials:true
  });
LysepAPI.interceptors.response.use(resp => resp, async (err) => {
  if (err?.response?.status === 401) {
    // Dispatch your Redux action here
    LFEStore.dispatch(Logout());
  }
  return Promise.reject(err);
});
export default LysepAPI;