import { Description, People } from "@mui/icons-material";
import { Component } from "react";



export default class LSModule extends Component{


    // constructor(props){
    //     super(props)
    // }
    componentDidMount(){

    }
    render(){
        
        return (<>
        
        </>)
    }
}
